/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-object-spread */
import { createReducer } from 'reduxsauce';
import lodash from 'lodash';
import Types from '../Actions/Types';
import { holdingsGroups } from '../Actions/watchlistActions';

export const INITIAL_STATE = {
	limits: {
		lists: 21,
		holdings: 20
	},
	fetchingWatchlist: false,
	fetchingWatchlistNames: false,
	watchlist: {},
	watchlists: [],
	currentCurrency: 'CAD',
	activeWatchlistId: null,
	activeWatchlistTab: null,
	holdingsByIssueType: {},
	fetchingWatchlistQuoteData: false,
	fetchingUpdateTransaction: false,
	fetchingCreateNewWatchlist: false,
	fetchingDeleteHoldings: false,
	fetchingDeleteWatchlist: false,
	hasUserLists: false,
	maxListsReached: false,
	maxHoldingsReached: false
};

const requestWatchlistNames = state => {
	return Object.assign({}, state, {
		fetchingWatchlistNames: true
	});
};

const receiveWatchlistNames = (state, action) => {
	const watchlists = action.response.data;
	if (watchlists && watchlists.length > 1) {
		let recentIndex = -1;
		const { length } = watchlists;
		for (let i = 0; i < length; i += 1) {
			if (watchlists[i].name === '__RECENT__') {
				recentIndex = i;
				break;
			}
		}

		if (recentIndex > 0) {
			const recentWatchlist = watchlists[recentIndex];
			for (let i = recentIndex; i > 0; i -= 1) {
				watchlists[i] = watchlists[i - 1];
			}
			watchlists[0] = recentWatchlist;
		}
	}

	return Object.assign({}, state, {
		fetchingWatchlistNames: false,
		watchlists: Object.assign([], watchlists),
		maxListsReached: action.response.data
			&& action.response.data.length
			&& action.response.data.length >= state.limits.lists
	});
};

const requestWatchlist = (state, action) => {
	return Object.assign({}, state, {
		fetchingWatchlist: true,
		currentCurrency: action.params.currency,
		activeWatchlistId: action.activeWatchlistId,
		activeWatchlistTab: action.activeWatchlistTab
	});
};

const receiveWatchlist = (state, action) => {
	const mappedByIssueType = {
		etfs: [],
		mutualFunds: [],
		options: [],
		stocks: []
	};

	if (action.response && action.response.data && action.response.data.holdings) {
		const { holdings } = action.response.data;
		holdings.forEach(holding => {
			let { issueType } = holding;
			if (issueType) {
				issueType = issueType.toLowerCase();
			} else {
				issueType = null;
			}

			switch (issueType) {
				case 'etf':
				case 'etn':
					mappedByIssueType.etfs.push(holding);
					break;
				case 'mf':
				case 'mmf':
					mappedByIssueType.mutualFunds.push(holding);
					break;
				case 'op':
					mappedByIssueType.options.push(holding);
					break;
				default:
					mappedByIssueType.stocks.push(holding);
			}
		});
	}

	return Object.assign({}, state, {
		fetchingWatchlist: false,
		watchlist: Object.assign({}, action.response.data),
		maxHoldingsReached: action.response.data
			&& action.response.data.holdings
			&& action.response.data.holdings.length
			&& action.response.data.holdings.length >= state.limits.holdings,
		holdingsByIssueType: Object.assign({}, mappedByIssueType)
	});
};

const requestWatchlistQuotes = state => {
	return Object.assign({}, state, {
		fetchingWatchlistQuoteData: true
	});
};

const receiveWatchlistQuotes = (state, action) => {
	const {
		options,
		etfs,
		// mutualFunds,
		stocks
	} = state.holdingsByIssueType;
	const quotesData = action.response.data;
	let newEtfs = etfs;
	// let newMutualFunds = mutualFunds;
	let newOptions = options;
	let newStocks = stocks;
	const byXid = {};

	if (quotesData && quotesData.quotes && quotesData.quotes.length > 0) {
		quotesData.quotes.forEach(quote => {
			const { data, error } = quote || {};
			if (data && data.lastTrade && data.lastTrade.open === 0) {
				data.lastTrade.open = null;
			}
			if (!error) {
				byXid[data.venueXid] = data;
			}
		});
	}

	if (options && options.length) {
		newOptions = options.map(option => {
			const quote = byXid[option.xid];

			if (quote) {
				option.strikePrice = quote.strikePrice;
				option.currency = quote.currency.isoCode;
				option.putCall = quote.putCall;
				option.lastPrice = quote.lastTrade.last;
				option.lastDate = quote.lastTrade.date;
				option.priceChange = quote.lastTrade.change;
				option.percentChange = quote.changePercent.today;
				option.bid = quote.bid.price;
				option.bidSize = quote.bid.size;
				option.ask = quote.ask.price;
				option.askSize = quote.ask.size;
				option.volume = quote.volume.last;
				option.high = quote.lastTrade.high;
				option.low = quote.lastTrade.low;
				option.open = quote.lastTrade.open === 0 ? null : quote.lastTrade.open;
				option.price52WeekHigh = quote.price52Week.high;
				option.price52WeekLow = quote.price52Week.low;
				option.isRealTime = quote.realTimeQuoteIndicator;
				option.expirationDate = quote.expirationDate;
			}
			return option;
		});
	}

	if (etfs && etfs.length) {
		newEtfs = etfs.map(etf => {
			const quote = byXid[etf.xid];
			if (quote) {
				etf.strikePrice = quote.strikePrice;
				etf.currency = quote.currency.isoCode;
				etf.putCall = quote.putCall;
				etf.lastPrice = quote.lastTrade.last;
				etf.lastDate = quote.lastTrade.date;
				etf.priceChange = quote.lastTrade.change;
				etf.percentChange = quote.changePercent.today;
				etf.bid = quote.bid.price;
				etf.bidSize = quote.bid.size;
				etf.ask = quote.ask.price;
				etf.askSize = quote.ask.size;
				etf.volume = quote.volume.last;
				etf.high = quote.lastTrade.high;
				etf.low = quote.lastTrade.low;
				etf.open = quote.lastTrade.open === 0 ? null : quote.lastTrade.open;
				etf.price52WeekHigh = quote.price52Week.high;
				etf.price52WeekLow = quote.price52Week.low;
				etf.isRealTime = quote.realTimeQuoteIndicator;
				etf.financialStatusIndicator = quote.financialStatusIndicator;
			}
			return etf;
		});
	}

	if (stocks && stocks.length) {
		newStocks = stocks.map(stock => {
			const quote = byXid[stock.xid];

			if (quote) {
				stock.strikePrice = quote.strikePrice;
				stock.currency = quote.currency.isoCode;
				stock.exchange = quote.currency.isoCode === 'CAD' ? 'CA' : 'US';
				stock.putCall = quote.putCall;
				stock.lastPrice = quote.lastTrade.last;
				stock.lastDate = quote.lastTrade.date;
				stock.priceChange = quote.lastTrade.change;
				stock.percentChange = quote.changePercent.today;
				stock.bid = quote.bid.price;
				stock.bidSize = quote.bid.size;
				stock.ask = quote.ask.price;
				stock.askSize = quote.ask.size;
				stock.volume = quote.volume.last;
				stock.high = quote.lastTrade.high;
				stock.low = quote.lastTrade.low;
				stock.open = quote.lastTrade.open === 0 ? null : quote.lastTrade.open;
				stock.price52WeekHigh = quote.price52Week.high;
				stock.price52WeekLow = quote.price52Week.low;
				stock.isRealTime = quote.realTimeQuoteIndicator;
				stock.financialStatusIndicator = quote.financialStatusIndicator;
			}
			return stock;
		});
	}

	// NEED TO GET THE MUTUAL FUND QOUTE DATA

	return Object.assign({}, state, {
		fetchingWatchlist: false,
		holdingsByIssueType: Object.assign({}, state.holdingsByIssueType, {
			etfs: [...newEtfs],
			// mutualFunds: { ...newMutualFunds },
			options: [...newOptions],
			stocks: [...newStocks]
		})
	});
};

const sortHoldingsGroup = (state, action) => {
	const { holdingsByIssueType } = state;
	const { field, direction } = action;
	let sortDirection = direction;

	let groupSortDirection = direction;
	let groupSortField = null;
	let sortData = null;
	let fieldName = null;
	let directionName = null;
	switch (action.holdingGroup) {
		case holdingsGroups.ETFS:
			{
				const { etfsSortDirection, etfsSortField } = state;
				const { etfs } = holdingsByIssueType;

				groupSortDirection = etfsSortDirection;
				groupSortField = etfsSortField;
				sortData = etfs;
				fieldName = 'etfsSortField';
				directionName = 'etfsSortDirection';
			} break;
		case holdingsGroups.MUTUALFUNDS:
			{
				const { mutualFundsSortDirection, mutualFundsSortField } = state;
				const { mutualFunds } = holdingsByIssueType;

				groupSortDirection = mutualFundsSortDirection;
				groupSortField = mutualFundsSortField;
				sortData = mutualFunds;
				fieldName = 'mutualFundsSortField';
				directionName = 'mutualFundsSortDirection';
			} break;
		case holdingsGroups.OPTIONS:
			{
				const { optionsSortDirection, optionsSortField } = state;
				const { options } = holdingsByIssueType;

				groupSortDirection = optionsSortDirection;
				groupSortField = optionsSortField;
				sortData = options;
				fieldName = 'optionsSortField';
				directionName = 'optionsSortDirection';
			} break;
		case holdingsGroups.STOCKS:
			{
				const { stocksAndIndicesSortDirection, stocksAndIndicesSortField } = state;
				const { stocks } = holdingsByIssueType;

				groupSortDirection = stocksAndIndicesSortDirection;
				groupSortField = stocksAndIndicesSortField;
				sortData = stocks;
				fieldName = 'stocksAndIndicesSortField';
				directionName = 'stocksAndIndicesSortDirection';
			} break;
		default:
			// NOTE(rick): Intentionally left empty
			break;
	}

	if (sortData) {
		if ((groupSortDirection && groupSortField === field)
			|| (groupSortDirection === direction && groupSortField === field)) {
			sortDirection = groupSortDirection === 'asc' ? 'desc' : 'asc';
		}

		const dataToSortByField = [];
		const dataToSortBySymbol = [];
		sortData.forEach(item => {
			if ((item.active === true) && (item[field] === undefined)) {
				dataToSortBySymbol.push(item);
			} else {
				dataToSortByField.push(item);
			}
		});

		const dataSortedByField = lodash.orderBy(dataToSortByField, field, [sortDirection]);
		const dataSortedBySymbol = lodash.orderBy(dataToSortBySymbol, 'symbol', ['asc']);
		const newData = [...dataSortedByField, ...dataSortedBySymbol];
		const newHoldingsByIssueType = {};
		newHoldingsByIssueType[action.holdingGroup] = [...newData];

		const newStateObject = {};
		newStateObject[fieldName] = field;
		newStateObject[directionName] = sortDirection;
		newStateObject.holdingsByIssueType = Object.assign({},
			state.holdingsByIssueType, newHoldingsByIssueType);

		return Object.assign({}, state, newStateObject);
	}

	return state;
};

const setWatchlistTableSortIndicators = (state, action) => {
	const { field, direction } = action;
	let fieldName = null;
	let directionName = null;
	switch (action.holdingGroup) {
		case holdingsGroups.ETFS:
			fieldName = 'etfsSortField';
			directionName = 'etfsSortDirection';
			break;
		case holdingsGroups.OPTIONS:
			fieldName = 'optionsSortField';
			directionName = 'optionsSortDirection';
			break;
		case holdingsGroups.STOCKS:
			fieldName = 'stocksAndIndicesSortField';
			directionName = 'stocksAndIndicesSortDirection';
			break;
		case holdingsGroups.MUTUALFUNDS:
			fieldName = 'mutualFundsSortField';
			directionName = 'mutualFundsSortDirection';
			break;
		default:
			// NOTE(rick): Intentionally left empty
			break;
	}

	if (fieldName && directionName) {
		const newStateObject = {};
		newStateObject[fieldName] = field;
		newStateObject[directionName] = direction;

		return Object.assign({}, state, newStateObject);
	}

	return state;
};

const requestUpdateTransaction = state => {
	return Object.assign({}, state, {
		fetchingWatchlist: false,
		fetchingUpdateTransaction: true
	});
};

const receiveUpdateTransaction = state => {
	return Object.assign({}, state, {
		fetchingWatchlist: false,
		fetchingUpdateTransaction: false
	});
};

const requestCreateNewWatchlist = state => {
	return Object.assign({}, state, {
		fetchingCreateNewWatchlist: true
	});
};

const receiveCreateNewWatchlist = (state, action) => {
	return Object.assign({}, state, {
		fetchingCreateNewWatchlist: true,
		fetchingWatchlist: false,
		watchlists: Object.assign([], action.response.data),
		activeWatchlistId: action.portfolioId
	});
};

const requestAddHoldings = state => {
	return Object.assign({}, state, {
		fetchingWatchlist: false,
		fetchingAddHoldings: true
	});
};

const receiveAddHoldings = state => {
	const {
		fetchingDeleteHoldings,
		fetchingRenameWatchlist
	} = state;

	if (!fetchingDeleteHoldings && !fetchingRenameWatchlist) {
		navigateToWatchlist();
	}

	return Object.assign({}, state, {
		fetchingAddHoldings: false
	});
};

const requestDeleteHoldings = state => {
	return Object.assign({}, state, {
		fetchingDeleteHoldings: true
	});
};

const receiveDeleteHoldings = state => {
	const {
		fetchingAddHoldings,
		fetchingRenameWatchlist
	} = state;

	if (!fetchingAddHoldings && !fetchingRenameWatchlist) {
		navigateToWatchlist();
	}

	return Object.assign({}, state, {
		fetchingDeleteHoldings: false
	});
};

const requestRenameWatchlist = state => {
	return Object.assign({}, state, {
		fetchingRenameWatchlist: true,
		renameWatchlistError: false
	});
};

const receiveRenameWatchlist = state => {
	const {
		fetchingAddHoldings,
		fetchingDeleteHoldings
	} = state;

	if (!fetchingAddHoldings && !fetchingDeleteHoldings) {
		navigateToWatchlist();
	}

	return Object.assign({}, state, {
		fetchingRenameWatchlist: false,
		renameWatchlistError: false
	});
};

const setRenameWatchlistError = (state, action) => {
	return Object.assign({}, state, {
		fetchingRenameWatchlist: false,
		renameWatchlistError: action.value
	});
};

const requestDeleteWatchlist = state => {
	return Object.assign({}, state, {
		fetchingDeleteWatchlist: true
	});
};

const receiveDeleteWatchlist = state => {
	let newId = null;
	if (state.watchlists && state.watchlists.length > 0) {
		const foundDefaultIndex = lodash.findLastIndex(state.watchlists,
			o => { return o.defaultPortfolio === true; });

		if (foundDefaultIndex !== -1
			&& state.watchlists[foundDefaultIndex].portfolioId !== state.activeWatchlistId) {
			newId = state.watchlists[foundDefaultIndex].portfolioId;
		} else {
			newId = state.watchlists[0].portfolioId;
		}
	}
	window.setTimeout(() => {
		navigateToWatchlist();
	}, 500);
	return Object.assign({}, state, {
		activeWatchlistId: newId,
		fetchingDeleteWatchlist: false
	});
};

const navigateToWatchlist = () => {
	const { location } = window;
	const { pathname } = location || {};

	if (pathname !== '/quotelists/watchlist') {
		window.location.href = '/quotelists/watchlist';
	}
};

const receiveAddToRecentQuotes = state => {
	return Object.assign({}, state, {
		fetchingAddToRecentQuotes: false
	});
};

const requestAddToRecentQuotes = state => {
	return Object.assign({}, state, {
		fetchingAddToRecentQuotes: true
	});
};

const setActiveWatchilistId = (state, action) => {
	return Object.assign({}, state, {
		activeWatchlistId: action.activeWatchlistId
	});
};

/**
 * Called by the saga in the finally block to ensure that in all cases the
 * fetching state for the call is set back to false to avoid infinite loader
 * and the ability to display both loaders and "sorry no data" messages.
 * @param {object} state
 * @param {object} action
 */
const handleLoader = (state, action) => {
	return Object.assign({}, state, {
		[action.fetchName]: false
	});
};

// map our types to our handlers
const ACTION_HANDLERS = {
	[Types.API_CIBC_REQUEST_WATCHLIST_NAMES]: requestWatchlistNames,
	[Types.API_CIBC_RECEIVE_WATCHLIST_NAMES]: receiveWatchlistNames,
	[Types.API_CIBC_REQUEST_WATCHLIST]: requestWatchlist,
	[Types.API_CIBC_RECEIVE_WATCHLIST]: receiveWatchlist,
	[Types.API_REQUEST_WATCHLIST_QUOTE]: requestWatchlistQuotes,
	[Types.API_RECEIVE_WATCHLIST_QUOTE]: receiveWatchlistQuotes,
	[Types.API_CIBC_REQUEST_WATCHLIST_UPDATE_TRANSACTION]: requestUpdateTransaction,
	[Types.API_CIBC_RECEIVE_WATCHLIST_UPDATE_TRANSACTION]: receiveUpdateTransaction,
	[Types.API_CIBC_REQUEST_WATCHLIST_CREATE_WATCHLIST]: requestCreateNewWatchlist,
	[Types.API_CIBC_RECEIVE_WATCHLIST_CREATE_WATCHLIST]: receiveCreateNewWatchlist,
	[Types.API_CIBC_REQUEST_WATCHLIST_ADD_HOLDINGS]: requestAddHoldings,
	[Types.API_CIBC_RECEIVE_WATCHLIST_ADD_HOLDINGS]: receiveAddHoldings,
	[Types.API_CIBC_RECEIVE_WATCHLIST_DELETE_HOLDINGS]: receiveDeleteHoldings,
	[Types.API_CIBC_REQUEST_WATCHLIST_DELETE_HOLDINGS]: requestDeleteHoldings,
	[Types.API_CIBC_RECEIVE_WATCHLIST_RENAME]: receiveRenameWatchlist,
	[Types.API_CIBC_SET_WATCHLIST_RENAME_ERROR]: setRenameWatchlistError,
	[Types.API_CIBC_REQUEST_WATCHLIST_RENAME]: requestRenameWatchlist,
	[Types.API_CIBC_RECEIVE_WATCHLIST_DELETE]: receiveDeleteWatchlist,
	[Types.API_CIBC_REQUEST_WATCHLIST_DELETE]: requestDeleteWatchlist,
	[Types.SORT_WATCHLIST]: sortHoldingsGroup,
	[Types.API_HANDLE_WATCHLIST_LOADER]: handleLoader,
	[Types.SORT_WATCHLIST_SETINDICATORS]: setWatchlistTableSortIndicators,
	[Types.API_CIBC_RECEIVE_ADD_TO_RECENT_QUOTES]: receiveAddToRecentQuotes,
	[Types.API_CIBC_REQUEST_ADD_TO_RECENT_QUOTES]: requestAddToRecentQuotes,
	[Types.SET_ACTIVE_WATCHLIST_ID]: setActiveWatchilistId
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
